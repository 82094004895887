import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Colors } from '../styles/global';
import { TextParagraphNormal, TextTitle, TitleSection } from '../components/texts';
import Header from '../components/Header'
import { useDispatch, useSelector } from 'react-redux'
import { login, loginAll } from '../actions/userActions'
import Footer from '../components/Footer'
import '../styles/facilitator.css';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import { Form, Row, Col, Button, Modal, InputGroup } from 'react-bootstrap';
import axios from 'axios';
import { refresh } from '../components/Images';
import { Link , useNavigate} from "react-router-dom";
import { ButtonCommon } from '../components/buttons';

let pageForm = 1

/* Styled Component */
const ContainerMain = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 80px;
`;
const SignUpContainer = styled.div`
  width: 70%;
  height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  box-shadow: 0px 4px 8px 4px rgba(0, 0, 0, 0.3);
  background-color: #ffffff;
  @media (max-width: 768px) {
    width: 100%;
    height: auto;
    border-radius: 0px;
    box-shadow:none;
  }
`;
const ContainerForm = styled.div`
  width: 50%;
  height: 100%;
  @media (max-width: 1080px) {
    width: 100%;
    height: auto;
  }
`;

const ContainerImg = styled.div`
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${Colors.quaternary};
  border-radius: 0 20px 20px 0;
  @media (max-width: 1080px) {
    display: none;
  }
`;
/* Styled components para los tabs y formularios*/
const TabsContainerSC = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-top: 80px;
`;

const ContainerFormContentSC = styled.div`
  width: 70%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
`;

const StyledSelect = styled.select`
  width: 100%;
  height: 50px;
  border: 1px solid green;
  border-radius: 5px;
  background-color: white;
  padding: 10px 5px;
  margin-bottom: 10px;
`;

const CustomRadio = styled.input`

  &:checked {
    color: green;
    border-color: green;
    background-color: green;
  }
`;

const Overlay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Fondo opaco oscuro */
    display: ${({ showModal }) => (showModal ? 'block' : 'none')};
    z-index: 9999; /* Asegurar que esté por encima de todo */
`;

const ModalCard = styled.div`
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
`;

const Logo = styled.img`
    width: 300px;
    height: 120px;
    margin-bottom: 10px;
`;

const formatNumber = (number) => {
    return (number < 10) ? '0' + number : number
}

const getCurrentDate = () => {
    const current = new Date();
    const date = `${current.getFullYear()}-${formatNumber(current.getMonth() + 1)}-${formatNumber(current.getDate())}`
    const time = `${current.getHours()}:${formatNumber(current.getMinutes())}:${formatNumber(current.getSeconds())}`

    return `${date}T${time}`
}

function generateString(length) {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!*%&$';
    for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * characters.length));
    }

    return result;
}

function RegisterScreen() {

    const navigate = useNavigate()

    const [data, setData] = useState({
        "email": "",
        "username": "",
        "first_name": "",
        "last_name": "",
        "role_id": 2,
        "cell_phone": "",
        "address": "",
        "password": "",
        "depto":"",
        "city":"",
        "pet":false,
        "num_pets":0,
        "vehicle":false,
        "num_vehicles":0,
        "staff_status": false,
        "created_at": getCurrentDate(),
        "captcha_text_user": ""
    })
    const inputRequireds = [
        "email",
        "first_name",
        "last_name",
        "cell_phone",
        "city",
        "address",
        "password",
        "confirmPassword",
        "captcha_text_user"
    ]

    const dispatch = useDispatch()
    const [prevDisabled, setPrevDisabled] = useState(true)
    const [showModal, setShowModal] = useState(false)
    const [captcha, setCaptcha] = useState(generateString(6))

    const [currentStep, setCurrentStep] = useState(1);

    const [departamentos, setDepartamentos] = useState([]);
    const [municipios, setMunicipios] = useState([]);
    const [departamentoSeleccionado, setDepartamentoSeleccionado] = useState('');
    const [municipioSeleccionado, setMunicipioSeleccionado] = useState('');
    const [mostrarErrorUbicacion, setMostrarErrorUbicacion] = useState(false);

    const [selectedOptionPet, setSelectedOptionPet] = useState('');
    const [selectedOptionVehicle, setSelectedOptionVehicle] = useState('');
        
    const handleOptionPetChange =(event) =>{
        setSelectedOptionPet(event.target.value);
        }

        const handleOptionVehicleChange =(event) =>{
            setSelectedOptionVehicle(event.target.value);
            }
    

    useEffect(() => {
        // Función para obtener los departamentos
        async function fetchDepartamentos() {
            try {
                const response = await fetch('https://www.datos.gov.co/resource/xdk5-pm3f.json');
                if (!response.ok) {
                    throw new Error('Error al obtener los departamentos');
                }
                const data = await response.json();
                // Extraemos los nombres de los departamentos
                const departamentosList = [...new Set(data.map(depto => depto.departamento))];
                setDepartamentos(departamentosList);
                setMostrarErrorUbicacion(false);

            } catch (error) {
                console.error('Error al obtener los departamentos:', error);
            }
        }

        fetchDepartamentos();
    }, []);

    useEffect(() => {
        // Función para obtener los municipios de un departamento seleccionado
        async function fetchMunicipios() {
            if (departamentoSeleccionado !== '') {
                try {
                    const response = await fetch(`https://www.datos.gov.co/resource/xdk5-pm3f.json?departamento=${departamentoSeleccionado}`);
                    if (!response.ok) {
                        throw new Error('Error al obtener los municipios');
                    }
                    const data = await response.json();
                    // Extraemos los nombres de los municipios
                    const municipiosList = [...new Set(data.map(municipio => municipio.municipio))];
                    setMunicipios(municipiosList);
                    setMostrarErrorUbicacion(false);

                } catch (error) {
                    console.error('Error al obtener los municipios:', error);
                }
            } else {
                // Limpiamos la lista de municipios si no hay departamento seleccionado
                setMunicipios([]);
            }
        }

        fetchMunicipios();
    }, [departamentoSeleccionado]);

    const refrescarCaptcha = () => {
        setCaptcha(generateString(6))
    }

    const handleClose = () => {
        setShowModal(false)
        window.location.href = '/'
    }

    const handleChange = (e) => {
        let inputName = e.target.name
        let inputValue = e.target.value


        setData({
            ...data, [inputName]: inputValue
        })

        if (document.getElementById(`error_${inputName}`)) {
            document.getElementById(`error_${inputName}`).style.display = "none"
        }
        document.getElementsByName(inputName)[0].classList.remove("error")
    }
    const validateCaptcha = () => {
        let validation = (captcha === data.captcha_text_user)

        if (!validation) {
            document.getElementById(`error_captcha_text_user`).style.display = "block"
            document.getElementById(`error_captcha_text_user`).innerText = "El texto ingresado no coincide con el generado"
            document.getElementsByName('captcha_text_user')[0].classList.add("error")
        }

        return validation
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!validateForm()) { return }
        if (!validateCaptcha()) { return }

        let body_form = document.getElementById("body_form")
        let spinner_form = document.getElementById("spinner_form")
        let config = {
            headers: {
                'Content-Type': 'application/json',
                'accept': 'application/json'
            }
        }
        let dF = data

        body_form.style.opacity = ".5"
        spinner_form.style.display = "flex"

        const dataForm = {
            "email": dF.email,
            "username": dF.email,
            "first_name": dF.first_name,
            "last_name": dF.last_name,
            "role_id": dF.role_id,
            "cell_phone": dF.cell_phone,
            "depto": departamentoSeleccionado,
            "city": municipioSeleccionado,
            "address": dF.address,
            "pet":selectedOptionPet == 'yes' && true,
            "num_pets":dF.num_pets,
            "vehicle":selectedOptionVehicle == 'yes' && true,
            "num_vehicles":dF.num_vehicles,
            "password": dF.password,
            "created_at": getCurrentDate()
        }


        axios.post('https://thor-fast-api.herokuapp.com/user', dataForm, config).then(res => {
            if (res.status == 201) {
                body_form.style.opacity = "1"
                spinner_form.style.display = "none"
                setShowModal(true)
                
                setTimeout(() => {
                    
                    setShowModal(false);
                    navigate('/login')
                    return true

                }, 3000);
                

                
            }
        }).catch(error => {
            console.log("Error registrando usuario: ", error)
            body_form.style.opacity = "1"
            spinner_form.style.display = "none"
            setShowModal(true)
        });
               
       
        body_form.style.opacity = ".5"
        spinner_form.style.display = "flex"

    }

    const isValidEmail = (email) => {
        return /\S+@\S+\.\S+/.test(email);
    }

    const isValidPhoneNumber = (phoneNumber) => {
        // Eliminar espacios, guiones y otros caracteres no numéricos
        const cleanedPhoneNumber = phoneNumber.replace(/[^\d]/g, '');

        // Verificar si el número de teléfono tiene exactamente 10 dígitos
        return cleanedPhoneNumber.length === 10;
    };

    const isStrongPassword = (password) => {
        // La expresión regular valida si hay al menos 8 caracteres,
        // una letra minúscula, una letra mayúscula y un número en la contraseña.
        const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/;
        return regex.test(password);
    };


    const validateForm = () => {
        let response = true
        let currentDivStep = document.getElementById(`step_${currentStep}`)

        let inputsCurrentStep = currentDivStep.getElementsByTagName("input")
        if (currentStep === 1) {

            if (inputsCurrentStep.length > 0) {
                inputRequireds.forEach((inputName) => {
                    let element = document.getElementsByName(inputName)[0]
                    let errorSpan = document.getElementById(`error_${inputName}`)
                    if (element && errorSpan) {
                        if (
                            data[inputName] == "" && inputsCurrentStep[inputName]) {
                            response = false
                            errorSpan.innerText = "Por favor rellene este campo"
                            errorSpan.style.display = "block"
                            element.classList.add("error")
                        }

                        if (element.getAttribute('type') == "email" && !isValidEmail(data[inputName]) && data[inputName] != "") {
                            response = false
                            errorSpan.innerText = "Por favor ingrese un correo valido"
                            errorSpan.style.display = "block"
                            element.classList.add("error")
                        }


                        if (inputName === "cell_phone" && !isValidPhoneNumber(data[inputName]) && data[inputName] !== "") {
                            response = false;
                            errorSpan.innerText = "Por favor ingrese un número de celular válido";
                            errorSpan.style.display = "block";
                            element.classList.add("error");
                        }

                        if (inputName === "password") {
                            if (data[inputName] === "") {
                                response = false;
                                errorSpan.innerText = "Por favor rellene este campo";
                                errorSpan.style.display = "block";
                                element.classList.add("error");
                            } else if (!isStrongPassword(data[inputName])) {
                                response = false;
                                errorSpan.innerText =
                                    "La contraseña debe tener al menos 8 caracteres y contener una minúscula, una mayúscula y un número";
                                errorSpan.style.display = "block";
                                element.classList.add("error");
                            }
                        }
                        // Validate confirmPassword field
                        if (inputName === "confirmPassword" && data[inputName] !== data.password) {
                            response = false;
                            errorSpan.innerText = "Las contraseñas no coinciden";
                            errorSpan.style.display = "block";
                            element.classList.add("error");
                        }
                    }
                })
            }

        }

        if (currentStep === 2) {

            if (inputsCurrentStep.length > 0) {
                inputRequireds.forEach((inputName) => {
                    let element = document.getElementsByName(inputName)[0]
                    let errorSpan = document.getElementById(`error_${inputName}`)
                    if (element && errorSpan) {
                        if (
                            data[inputName] == "" && inputsCurrentStep[inputName]) {
                            response = false
                            errorSpan.innerText = "Por favor rellene este campo"
                            errorSpan.style.display = "block"
                            element.classList.add("error")
                        }

                        if (departamentoSeleccionado === '') {
                            response = false
                            setMostrarErrorUbicacion(true)

                        }
                        if (municipioSeleccionado === '') {
                            response = false
                            setMostrarErrorUbicacion(true)

                        }






                    }
                })
            }

        }

        if (currentStep === 3) {

            if (inputsCurrentStep.length > 0) {
                inputRequireds.forEach((inputName) => {
                    let element = document.getElementsByName(inputName)[0]
                    let errorSpan = document.getElementById(`error_${inputName}`)
                    if (element && errorSpan) {
                        if (
                            data[inputName] == "" && inputsCurrentStep[inputName]) {
                            response = false
                            errorSpan.innerText = "Por favor rellene este campo"
                            errorSpan.style.display = "block"
                            element.classList.add("error")
                        }



                        if (inputName === "password") {
                            if (data[inputName] === "") {
                                response = false;
                                errorSpan.innerText = "Por favor rellene este campo";
                                errorSpan.style.display = "block";
                                element.classList.add("error");
                            } else if (!isStrongPassword(data[inputName])) {
                                response = false;
                                errorSpan.innerText =
                                    "La contraseña debe tener al menos 8 caracteres y contener una minúscula, una mayúscula y un número";
                                errorSpan.style.display = "block";
                                element.classList.add("error");
                            }
                        }
                        // Validate confirmPassword field
                        if (inputName === "confirmPassword" && data[inputName] !== data.password) {
                            response = false;
                            errorSpan.innerText = "Las contraseñas no coinciden";
                            errorSpan.style.display = "block";
                            element.classList.add("error");
                        }
                    }
                })
            }

        }


        let selectsCurrentStep = currentDivStep.getElementsByTagName("select")
        if (selectsCurrentStep.length > 0) {
            for (let item of selectsCurrentStep) {
                if (data[item.name] == "" && selectsCurrentStep[item.name]) {
                    response = false
                    document.getElementById(`error_${item.name}`).style.display = "block"
                    document.getElementsByName(item.name)[0].classList.add("error")
                }
            }
        }
        return response
    }

    const nextPageForm = (e) => {
        if (!validateForm()) { return }

        /*
        let stepFormElements = document.getElementsByClassName("stepForm");
        if (pageForm <= stepFormElements.length) {
            for (let i = 0; i < stepFormElements.length; i++) {
                stepFormElements[i].setAttribute("hidden", true)
            }
      
            document.getElementById(`breadcrumb_${pageForm}`).classList.remove("active")
            pageForm += 1
            pageForm = (pageForm >= stepFormElements.length) ? stepFormElements.length : pageForm
            if (pageForm == stepFormElements.length) {
                e.target.setAttribute("hidden", true)
                document.getElementById("subm_btn").removeAttribute("hidden")
            } else {
                e.target.removeAttribute("hidden")
            }
      
            if (pageForm > 1) {
                setPrevDisabled(false)
            }
            document.getElementById(`step_${pageForm}`).removeAttribute("hidden")
            document.getElementById(`breadcrumb_${pageForm}`).classList.add("active")
        }
        */
    }

    const prevPageForm = (e) => {
        if (pageForm > 0) {
            let stepFormElements = document.getElementsByClassName("stepForm");
            for (let i = 0; i < stepFormElements.length; i++) {
                stepFormElements[i].setAttribute("hidden", true)
            }

            document.getElementById(`breadcrumb_${pageForm}`).classList.remove("active")
            pageForm -= 1
            pageForm = (pageForm == 0) ? 1 : pageForm


            if (pageForm == 1) {
                setPrevDisabled(true)
            } else {
                setPrevDisabled(false)
            }

            if (pageForm < stepFormElements.length) {
                document.getElementById("next_btn").removeAttribute("hidden")
                document.getElementById("subm_btn").setAttribute("hidden", true)
            }

            document.getElementById(`step_${pageForm}`).removeAttribute("hidden")
            document.getElementById(`breadcrumb_${pageForm}`).classList.add("active")
        }
    }

    const handleNextStep = () => {
        if (validateForm()) {
            setCurrentStep((prevStep) => prevStep + 1);
        }

    };

    const handleBackStep = () => {
        setCurrentStep((prevStep) => prevStep - 1);
    };

    return (
        <ContainerMain>
            
            <ContainerMain>
            <Overlay showModal={showModal}>
                <ModalCard>
                    <Logo src="./images/logo_sin_fondo.png" alt="Logo de la plataforma" />
                    <h2>Bienvenido</h2>
                    <p>Gracias por registrarte en TuColonia.co</p>
                </ModalCard>
            </Overlay>
                <SignUpContainer>
               
                    <ContainerForm>
                        <div style={{ display: "flex", flexDirection: "column", justifyContent: "start", alignItems: "start", width: "100%", height: "100%", marginTop: "20px", padding: "40px" }}>
                            <TitleSection style={{ marginBottom: '50px' }}>Registro</TitleSection>
                            <Form className='facilitator_container' onSubmit={handleSubmit}>
                                <div class="spinner" id="spinner_form">
                                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                    <p>Enviando...</p>
                                </div>
                                <div id='body_form' >

                                    {currentStep === 1 && (
                                        <div className='stepForm' id='step_1'>

                                            <Col md className='mb-3'>

                                                <FloatingLabel controlId="floatingInputGrid" label="🧑 Nombre(s)*">
                                                    <Form.Control type="text" placeholder="Nombres *" name='first_name' value={data.first_name} onChange={handleChange} />
                                                    <small id='error_first_name' className='form_error_input'>Por favor rellene este campo</small>
                                                </FloatingLabel>
                                            </Col>
                                            <Col md className='mb-3'>
                                                <FloatingLabel controlId="floatingInputGrid" label="🧑 Apellido(s) *">
                                                    <Form.Control type="text" placeholder="🧑 Apellidos *" name='last_name' value={data.last_name} onChange={handleChange} />
                                                    <small id='error_last_name' className='form_error_input'>Por favor rellene este campo</small>
                                                </FloatingLabel>
                                            </Col>

                                            <Col md className='mb-3'>
                                                <FloatingLabel controlId="floatingInputGrid" label="📱 Celular *">
                                                    <Form.Control type="number" placeholder="Celular 1 *" name='cell_phone' value={data.cell_phone} onChange={handleChange} />
                                                    <small id='error_cell_phone' className='form_error_input'>Por favor rellene este campo</small>
                                                </FloatingLabel>
                                            </Col>
                                            <Col md className='mb-3'>
                                                <FloatingLabel controlId="floatingInputGrid" label="📧 Correo Electrónico *">
                                                    <Form.Control type="email" placeholder="Correo Electrónico *" name='email' value={data.email} onChange={handleChange} />
                                                    <small id='error_email' className='form_error_input'>Por favor rellene este campo</small>
                                                </FloatingLabel>
                                            </Col>


                                        </div>

                                    )}

                                    {currentStep === 2 && (

                                        <div className='stepForm' id='step_2'>

                                            <div className='mb-3' style={{ display: "flex", flexDirection: "column" }}>
                                                <label htmlFor="departamentos">Departamento residencial:</label>
                                                <StyledSelect id="departamentos" value={departamentoSeleccionado} onChange={(e) => setDepartamentoSeleccionado(e.target.value)}>
                                                    <option value="">Selecciona un departamento</option>
                                                    {departamentos.map(depto => (
                                                        <option key={depto} value={depto}>{depto}</option>
                                                    ))}
                                                </StyledSelect>


                                                <label htmlFor="municipios">Municipio residencial:</label>
                                                <StyledSelect id="municipios" value={municipioSeleccionado} onChange={(e) => setMunicipioSeleccionado(e.target.value)}>
                                                    <option value="">Selecciona un municipio</option>
                                                    {municipios.map(municipio => (
                                                        <option key={municipio} value={municipio}>{municipio}</option>
                                                    ))}
                                                </StyledSelect>
                                                {mostrarErrorUbicacion == true && (<small style={{ color: 'red' }}>Por favor selecciona un Depto y un Municipio</small>)}
                                            </div>

                                            <Col md className='mb-3'>
                                                <FloatingLabel controlId="floatingInputGrid" label="🏢 Dirección *">
                                                    <Form.Control type="text" placeholder="Dirección *" name='address' value={data.address} onChange={handleChange} />
                                                    <small id='error_address' className='form_error_input'>Por favor rellene este campo</small>
                                                </FloatingLabel>
                                            </Col>

                                            <label>Tienes Mascotas?</label>
                                            <div style={{display: "flex", flexDirection: "row", justifyContent: "start", alignItems: "center"}}>
                                             
                                          

                                            <label style={{padding: "10px"}}>
                                                    <CustomRadio
                                                        type="radio"
                                                        value="yes"
                                                        checked={selectedOptionPet === 'yes'}
                                                        onChange={handleOptionPetChange}
                                                    />
                                                    Sí
                                                </label>
                                                <label>
                                                    <CustomRadio
                                                        type="radio"
                                                        value="no"
                                                        checked={selectedOptionPet === 'no'}
                                                        onChange={handleOptionPetChange}
                                                    />
                                                    No
                                                </label>

                                                
                                               
                                                {selectedOptionPet === 'yes' && (<Col md className='ms-3'>
                                                    <FloatingLabel controlId="floatingInputGrid" label="🐶 Número de Mascotas *" >
                                                        <Form.Control type="number" placeholder="Cuántas *" name='num_pets' value={data.num_pets} onChange={handleChange} />
                                                    </FloatingLabel>
                                                </Col>)}
                                                
                                            </div>

                                            <label>Tienes Vehículos?</label>
                                            <div style={{display: "flex", flexDirection: "row", justifyContent: "start", alignItems: "center"}}>
                                            
                                                <label style={{padding: "10px"}}>
                                                    <CustomRadio
                                                        type="radio"
                                                        value="yes"
                                                        checked={selectedOptionVehicle === 'yes'}
                                                        onChange={handleOptionVehicleChange}
                                                    />
                                                    Sí
                                                </label>
                                                <label>
                                                    <CustomRadio
                                                        type="radio"
                                                        value="no"
                                                        checked={selectedOptionVehicle === 'no'}
                                                        onChange={handleOptionVehicleChange}
                                                    />
                                                    No
                                                </label>
                                                {selectedOptionVehicle === 'yes' && (<Col md className='ms-3'>
                                                    <FloatingLabel controlId="floatingInputGrid" label="🏍️🚙 Número de Vehículos *" >
                                                        <Form.Control type="number" placeholder="Cuántas *" name='num_vehicles' value={data.num_vehicles} onChange={handleChange} />
                                                    </FloatingLabel>
                                                </Col>)}
                                                
                                            </div>




                                        </div>)}


                                    {currentStep === 3 && (

                                        <div className='stepForm' id='step_3'>

                                            <Col md className='mb-3'>
                                                <FloatingLabel controlId="floatingInputGrid" label="🔏 Contraseña *">
                                                    <Form.Control type="password" placeholder="🔏 Contraseña" name='password' value={data.password} onChange={handleChange} />
                                                    <small id='error_password' className='form_error_input'>Por favor rellene este campo</small>
                                                </FloatingLabel>
                                            </Col>
                                            <Col md className='mb-3'>
                                                <FloatingLabel controlId="floatingInputGrid" label="🔏 Confirmar Contraseña">
                                                    <Form.Control type="password" placeholder="Confirmar Contraseña *" name='confirmPassword' onChange={handleChange} />
                                                    <small id='error_confirmPassword' className='form_error_input'>Por favor rellene este campo</small>
                                                </FloatingLabel>
                                            </Col>

                                            <Row className='g-2 captcha'>
                                                <Col md className='mb-3'>
                                                    <label>Captcha</label>
                                                    <InputGroup>
                                                        <FloatingLabel controlId="floatingInputGrid">
                                                            <Form.Control type="text" className="input_disabled_captcha" value={captcha} disabled />
                                                            <small id='error_facilitator_would_like' className='form_error_input'>Por favor rellene este campo</small>
                                                        </FloatingLabel>
                                                        <Button title='Refrescar captcha' onClick={refrescarCaptcha}>
                                                            <img style={{ width: '40px' }} src={refresh} />
                                                        </Button>
                                                    </InputGroup>
                                                </Col>
                                            </Row>
                                            <Col md className='mb-3'>
                                                <label>Ingresa el texto del captcha *</label>
                                                <FloatingLabel controlId="floatingInputGrid" label="Ingresa el texto del captcha *">
                                                    <Form.Control type="text" placeholder="Ingresa el texto del captcha *" name='captcha_text_user' value={data.captcha_text_user} onChange={handleChange} />
                                                    <small id='error_captcha_text_user' className='form_error_input'>Por favor rellene este campo</small>
                                                </FloatingLabel>
                                            </Col>

                                        </div>

                                    )}

                                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100%", marginTop: '10px', gap: '10px' }}>
                                        {currentStep === 1 ? (<img style={{ width: '20px', height: '20px' }} src='./images/registro/hormigaRegisterfill.svg' />) : (<img style={{ width: '20px', height: '20px' }} src='./images/registro/hormigaRegisterout.svg' />)}
                                        {currentStep === 2 ? (<img style={{ width: '20px', height: '20px' }} src='./images/registro/hormigaRegisterfill.svg' />) : (<img style={{ width: '20px', height: '20px' }} src='./images/registro/hormigaRegisterout.svg' />)}
                                        {currentStep === 3 ? (<img style={{ width: '20px', height: '20px' }} src='./images/registro/hormigaRegisterfill.svg' />) : (<img style={{ width: '20px', height: '20px' }} src='./images/registro/hormigaRegisterout.svg' />)}
                                    </div>

                                    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%", marginTop: '10px' }}>
                                        {currentStep !== 1 && (<ButtonCommon id='prev_btn' text={'Atrás'} typeForm={'out'} onClick={handleBackStep} />)}
                                        {currentStep === 1 && (<ButtonCommon id='next_btn' text={'Siguiente'} typeForm={'fill'} onClick={handleNextStep} />)}
                                        {currentStep === 2 && (<ButtonCommon id='next_btn' text={'Siguiente'} typeForm={'fill'} onClick={handleNextStep} />)}
                                        {currentStep === 3 && (<ButtonCommon id='subm_btn' text={'Enviar'} typeForm={'fill'} type="submit" />)}

                                    </div>
                                    <div style={{ marginTop: '50px' }}>
                                        * Al registrarte aceptas nuestros
                                        <Link className='link_register' to='/terminos'> Términos y condiciones</Link> y nuestra
                                        <Link className='link_register' to='/politica'> Política de Datos</Link>
                                    </div>

                                </div>
                            </Form>
                        </div>
                    </ContainerForm>
                    <ContainerImg>
                        <TextTitle style={{ color: 'white', textAlign: 'center', height: "20%", margin: "20px" }}>Estás a un paso de pertenercer a la mayor red de compras colectivas de Colombia.<br /> ¡Regístrate! </TextTitle>
                        <img style={{ width: "100%", height: "80%" }} alt="registrate" src='./images/registro/registrate.svg' />
                    </ContainerImg>
                </SignUpContainer>
                <Footer />
            </ContainerMain>

        </ContainerMain>
       
    )
}

export default RegisterScreen

