import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Tabs, { tabsClasses } from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
// react icons
import { FaFilter } from 'react-icons/fa';
import { Colors } from '../styles/global';
import { Link } from 'react-router-dom';

import { styled } from '@mui/system';
import CardPromo from './CardPromo';
import { Card, CardContent, Typography, Grid, CircularProgress } from '@mui/material';


const CustomTabs = styled(Tabs)({
  '& .MuiTabs-indicator': {
    backgroundColor: '#87B338', // Cambia a tu código hexadecimal
  },
});

const CustomTab = styled(Tab)({
  color: '#666666', // Cambia a tu color hexadecimal deseado
  '&.Mui-selected': {
    color: '#635994', // Cambia a tu color hexadecimal deseado para el texto seleccionado
    fontWeight: 'bold',
  },
});


// Objeto de ejemplo con las categorías de productos
const locationsTab = [
  { id: 1, icon: '🚗', label: 'autos' },
  { id: 2, icon: '🐶', label: 'mascotas' },
  { id: 3, icon: '🏠', label: 'hogar' },
  { id: 4, icon: '📱', label: 'tecnologia' },

  // Agrega más categorías si es necesario
];

const obtenerIdPorCategoria = (categoria) => {
  const categoriaEncontrada = locationsTab.find((item) => item.label.toLowerCase() === categoria.toLowerCase());
  return categoriaEncontrada ? categoriaEncontrada.id : null;
};


const OptionsTab = ({ categoria }) => {


  const [loading, setLoading] = useState(true);
  const [promotions, setPromotions] = useState([]);
  const [value, setValue] = React.useState(0); // Estado para controlar la pestaña seleccionada
  const [categoriaSeleccionada, setCategoriaSeleccionada] = React.useState(categoria);

  useEffect(() => {
    fetchPromotions();
  }, [categoriaSeleccionada]);

  const fetchPromotions = () => {
    setLoading(true);
    const id = obtenerIdPorCategoria(categoriaSeleccionada);
    

    fetch(`https://thor-fast-api.herokuapp.com/promotion/category/${id}`)
    //fetch(`https://thor-fast-api.herokuapp.com/promotion/`)
      .then(response => response.json())
      .then(data => {
        setPromotions(data);
        setLoading(false);
        console.log(data)
      })
      .catch(error => {
        console.error('Error fetching promotions:', error);
        setLoading(false);
      });
  };


  React.useEffect(() => {
    // Si hay una categoría especificada, encontrar su índice en el array de categorías
    if (categoria) {
      const index = locationsTab.findIndex(tab => tab.label.toLowerCase() === categoria.toLowerCase());
      // Si se encuentra la categoría, establecer su índice como valor de la pestaña seleccionada
      //console.log(index)
      if (index !== -1) {
        setValue(index);
        setCategoriaSeleccionada(locationsTab[index].label);
      }
    }
  }, [categoria]); // Ejecutar este efecto cuando cambie la categoría

  const handleChange = (event, newValue) => {
    setValue(newValue);
    setCategoriaSeleccionada(locationsTab[newValue].label);
  };

  return (
    <div style={{ width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
      <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
        <Container maxWidth="xl">
          <Box
            sx={{
              display: 'flex',
              flexGrow: 1,
              px: { xs: 0, md: 2 },
              alignItems: 'center',
              mt: 2,
              mb: 2,
              fontSize: '4rem',
            }}
          >
            <CustomTabs
              value={value}
              onChange={handleChange}
              variant="scrollable"
              scrollButtons
              sx={{
                [`& .${tabsClasses.scrollButtons}`]: {
                  '&.Mui-disabled': { opacity: 0.3 },
                },
              }}
            >
              {locationsTab.map((tab, index) => (
                <Link key={tab.id} to={`/promociones/${tab.label.toLowerCase()}`} style={{ textDecoration: 'none', color: 'inherit' }}>
                  <CustomTab selected={index === value} icon={tab.icon} label={tab.label} />
                </Link>
              ))}
            </CustomTabs>
          </Box>
        </Container>
      </div>

      
      {categoriaSeleccionada && (
  <div style={{ width: '100%', padding: '10px', marginTop: '10px', color: 'white'}}>
    <Grid container spacing={3}>
      {loading ? (
        <Grid item xs={12} style={{ textAlign: 'center' }}>
          <CircularProgress />
        </Grid>
      ) : (
        // Utilizamos el operador ternario para mostrar las promociones si hay alguna
// Suggested code may be subject to a license. Learn more: ~LicenseLog:3216015673.
        <div style={{ width: '100%',display: 'flex', justifyContent: 'start', alignItems: 'center', flexWrap: 'wrap',  gap: '10px', marginRight: '10px' }}>
{promotions.length > 0 ? (
          promotions.map((promotion) => (
            <CardPromo key={promotion.id} promotion={promotion} />
          ))
        ) : (
          // Mostrar la imagen si no hay promociones
          <Grid item xs={12} style={{ textAlign: 'center' }}>
            <img src="/images/promociones/nohaypromos.svg" alt="Imagen de promoción no encontrada" />
            <Typography variant="subtitle1">No se encontraron promociones</Typography>
          </Grid>
        )}
        </div>
        )}
    </Grid>
  </div>
)}


    </div>
  );
};

export default OptionsTab;