import { TextParagraphNormal, TextTitle, TitleSection } from './texts';
import styled from 'styled-components';
import { Colors } from '../styles/global';
import { ButtonCommon } from './buttons';

const ContainerComoFunciona = styled.div`
display: block;
position: relative;
width: 100%;
padding: 0;
margin-top: 100px;
`

const ContainerSectionsComoFunciona = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 1400px;
    margin: 0 auto;
    padding: 0 20px;
`

const Title = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`

const ContainerSection = styled.div`
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
    margin-top: 50px;
    @media (max-width: 768px) {
      flex-direction: column !important;
    }
  `;

const ContainerContentText = styled.div`
    width: 50%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: start;
    padding: 20px;
    @media (max-width: 768px) {
      width: 100%;
    }
    `;

const ContainerImage = styled.div`
    width: 50%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    @media (max-width: 768px) {
      width: auto;
    }
  `;

const BackgroundSVG = styled.svg`
width: 100%;
height: auto; /* Cambiado de 100% a auto */

  `;

const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1400px;
  margin: 0 auto;
  padding: 0 20px;
`;

const ComoFunciona = () => {
    return (
        <ContainerComoFunciona id="como_funciona">
            {/* Secciones principales de como funciona */}
            <ContainerSectionsComoFunciona>
                {/* Titulo de como funciona */}
                <Title>
                    <TitleSection>Así hacemos compras inteligentes</TitleSection>
                    <img src='/images/comofunciona/hormiga.svg' style={{ width: '80px', height: 'auto' }}></img>
                </Title>

                {/* Seccion 01*/}
                <ContainerSection>
                    <ContainerContentText>
                        <TitleSection style={{ marginBottom: '50px' }}>Regístrate! 📄</TitleSection>
                        <TextTitle style={{ color: Colors.textAccent }}>¡Regístrate y empieza a ahorrar! </TextTitle>
                        <TextParagraphNormal>Encuentra cientos de promociones para desbloquear increíbles descuentos en tus productos favoritos.</TextParagraphNormal>
                    </ContainerContentText>
                    <ContainerImage>
                        <img style={{ width: '90%', height: 'auto' }} src='/images/comofunciona/registratehome.svg' />
                    </ContainerImage>
                </ContainerSection>

                {/* Seccion 02*/}
                <ContainerSection style={{ flexDirection: 'row-reverse' }}>
                    <ContainerContentText>
                        <TitleSection style={{ marginBottom: '50px' }}>Haz tu pedido! 🛍️</TitleSection>
                        <TextTitle style={{ color: Colors.textAccent }}>¡Regístrate y empieza a ahorrar! </TextTitle>
                        <TextParagraphNormal>Suscríbete a las promociones para desbloquear increíbles descuentos en tus productos favoritos.</TextParagraphNormal>
                    </ContainerContentText>
                    <ContainerImage>
                        <img style={{ width: '80%', height: 'auto' }} src='/images/comofunciona/hacerpedido.svg' />
                    </ContainerImage>
                </ContainerSection>

                {/* Seccion 03*/}
                <ContainerSection >
                    <ContainerContentText>
                        <TitleSection style={{ marginBottom: '50px' }}>Se hace magia! ✨</TitleSection>

                        <TextParagraphNormal>Tu Colonia al recibir tu suscripción en un producto genera un código promocional exclusivo para ti para que obtengas un descuento en tu compra.
                            Te ponemos en contacto con el proveedor y ellos te enviarán tu producto.</TextParagraphNormal>
                    </ContainerContentText>
                    <ContainerImage>
                        <img style={{ width: '90%', height: 'auto' }} src='/images/comofunciona/proveedor.svg' />
                    </ContainerImage>
                </ContainerSection>

                 {/* Seccion 04*/}
                 <ContainerSection style={{ flexDirection: 'row-reverse' }}>
                    <ContainerContentText>
                        <TitleSection style={{ marginBottom: '50px' }}>Disfruta! 😎</TitleSection>
                        
                        <TextParagraphNormal>Disfruta de tus productos favoritos con Tu Colonia.</TextParagraphNormal>
                    </ContainerContentText>
                    <ContainerImage>
                        <img style={{ width: '80%', height: 'auto' }} src='/images/comofunciona/personasCompras.svg' />
                    </ContainerImage>
                </ContainerSection>

            </ContainerSectionsComoFunciona>

            {/* seccion de registrate con fondo verde */}
            <BackgroundSVG viewBox="0 0 100 9" preserveAspectRatio="none">
                {/* Curva 1 */}
                <path d="M0,5 Q25,0 50,5 T110,5 V10 H0 Z" fill={Colors.quaternary} />

            </BackgroundSVG>
            <div style={{ width: '100%', height: '100%', backgroundColor: Colors.quaternary, padding: '100px 0', margin: '0' }}>
                <Content>
                    <ContainerImage>
                        <img style={{ width: '35%', height: 'auto' }} src='/images/comofunciona/mujercompras.svg' />
                    </ContainerImage>
                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%', alignItems: 'center', padding: '50px 0' }}>
                        <TitleSection style={{ textAlign: 'center', marginBottom: '50px' }}>¡Regístrate Ahora<br />
                            Sin Costo<br />
                            Rápido<br />
                            y Fácil !</TitleSection>
                        <ButtonCommon text='Regístrate' typeForm='fill' />
                    </div>
                    <ContainerImage>
                        <img style={{ width: '50%', height: 'auto' }} src='/images/comofunciona/hombrecompras.svg' />
                    </ContainerImage>


                </Content>

            </div>
            <BackgroundSVG viewBox="0 0 100 10" preserveAspectRatio="none">
                {/* Curva 1 */}
                <path d="M100,5 Q75,10 50,5 T0,5 V0 H100 Z" fill={Colors.quaternary} />

            </BackgroundSVG>

            {/* Seccion Trabaja con Nosotros [Facilitador o proveedor]*/}
            <ContainerSectionsComoFunciona style={{ marginTop: '100px' }}>
                <Title>
                    <TitleSection>Trabaja con Nosotros</TitleSection>

                </Title>

                <TextParagraphNormal style={{ textAlign: 'center', marginTop: '30px' }}>Puedes obtener ingresos adicionales si te vuelves un facilitador de Tu Colonia o proveedor donde puedes ofrecer tus productos/servicios y tener el mejor canal de ventas colectivas trabajando para ti.</TextParagraphNormal>

                {/* Seccion 01 Trabaja con nosotros como facilitador*/}
                <ContainerSection>
                    <ContainerContentText>
                        <TitleSection style={{ marginBottom: '50px' }}>Sé un Facilitador de Tu Colonia</TitleSection>
                        <Title>
                            <img src='/images/comofunciona/staremoji.svg' style={{ width: '40px', height: 'auto' }}></img>
                            <TextParagraphNormal style={{ marginLeft: '20px' }}>Obtienes ingresos promoviendo las Compras Colectivas Inteligentes.</TextParagraphNormal>
                        </Title>
                        <Title style={{ marginBottom: '30px' }}>
                            <img src='/images/comofunciona/staremoji.svg' style={{ width: '40px', height: 'auto' }}></img>
                            <TextParagraphNormal style={{ marginLeft: '20px' }}>No tienes que invertir, no tienes que comprar productos o servicios, no tienes que hacer cobros.</TextParagraphNormal>
                        </Title>
                        <ButtonCommon text='Quiero ser Facilitador' typeForm='fill' />

                    </ContainerContentText>
                    <ContainerImage>
                        <img style={{ width: '90%', height: 'auto' }} src='/images/comofunciona/facilitador.svg' />
                    </ContainerImage>
                </ContainerSection>

                {/* Seccion 02 Trabaja con nosotros como facilitador*/}
                <ContainerSection style={{ flexDirection: 'row-reverse' }}>
                    <ContainerContentText>
                        <TitleSection style={{ marginBottom: '50px' }}>Sé un Proveedor de Tu Colonia</TitleSection>
                        <Title>
                            <img src='/images/comofunciona/staremoji.svg' style={{ width: '40px', height: 'auto' }}></img>
                            <TextParagraphNormal style={{ marginLeft: '20px' }}>Al ser proveedor en tu colonia, conectas con la comunidad, lo que puede aumentar la visibilidad de tu marca y fortalecer tu presencia en el mercado.</TextParagraphNormal>
                        </Title>
                        <Title style={{ marginBottom: '30px' }}>
                            <img src='/images/comofunciona/staremoji.svg' style={{ width: '40px', height: 'auto' }}></img>
                            <TextParagraphNormal style={{ marginLeft: '20px' }}>puedes reducir los costos asociados con el transporte, el almacenamiento y la logística al hacer entregas masivas.</TextParagraphNormal>
                        </Title>
                        <ButtonCommon text='Quiero ser Proveedor' typeForm='fill' />

                    </ContainerContentText>
                    <ContainerImage>
                        <img style={{ width: '90%', height: 'auto' }} src='/images/comofunciona/acuerdoproveedor.svg' />
                    </ContainerImage>
                </ContainerSection>


            </ContainerSectionsComoFunciona>

 {/* seccion de registrate con fondo verde */}
 <BackgroundSVG viewBox="0 0 100 9" preserveAspectRatio="none">
                    {/* Curva 1 */}
                    <path d="M0,5 Q25,0 50,5 T110,5 V10 H0 Z" fill={Colors.quaternary} />

                </BackgroundSVG>
                <div style={{ width: '100%', height: '100%', backgroundColor: Colors.quaternary, padding: '100px 0', margin: '0' }}>
                    <Content>
                        
                        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', height: '100%', alignItems: 'center', padding: '50px 0' }}>
                            <TitleSection style={{ textAlign: 'center', marginBottom: '50px' }}>No Esperes más<br />
                            Y sé parte de:</TitleSection>
                            <ContainerImage>
                        <img style={{ width: '100%', height: 'auto', marginBottom: '30px' }} src='/images/logo_sin_fondo.png' />
                    </ContainerImage>
                            <ButtonCommon text='Regístrate' typeForm='fill' />
                        </div>
                      

                    </Content>

                </div>
                <BackgroundSVG viewBox="0 0 100 10" preserveAspectRatio="none">
                    {/* Curva 1 */}
                    <path d="M100,5 Q75,10 50,5 T0,5 V0 H100 Z" fill={Colors.quaternary} />

                </BackgroundSVG>


        </ContainerComoFunciona>

    )
}

export default ComoFunciona